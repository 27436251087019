// importing modules
import React, { useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonPage,
    IonRow,
} from '@ionic/react';
import groupMembers from '../../assets/group-members.svg';
import { chatbubbleEllipses, searchOutline, menuOutline } from 'ionicons/icons';
import GroupProfileHeader from '../../components/GroupProfileHeader/GroupProfileHeader';
import SkillsOfferedByGroup from '../../components/SkillsOfferedByGroup/SkillsOfferedByGroup';
import GroupTaskItem from '../../components/GroupTaskItem/GroupTaskItem';
import ProfileStateTabs from '../../components/ProfileStateTabs/ProfileStateTabs';
import UserMenu from '../../components/UserMenu/UserMenu';
import UserMenuBackdrop from '../../components/UserMenu/UserMenuBackdrop';
import Loading from '../../components/status/Loading';
import NavBar from '../../components/NavBar';
// importing utilities
import { FirebaseHelper } from '../../helpers/FirebaseHelper';
import {
    setLocalStorage,
    getLocalStorage,
    updateLocalStorage,
    deleteLocalStorage,
} from '../../helpers/localStorage/localStorageHelper';
// importing graphql
import { useQuery } from '@apollo/react-hooks';
import { GET_ORG_BY_ID } from '../../GraphQL/organization/GET_ORG_BY_ID/GET_ORG_BY_ID';
import {
    GetOrgById,
    GetOrgByIdVariables,
} from '../../GraphQL/__generated__/GetOrgById';
import { Status } from '../../GraphQL/__generated__/globalTypes';
// importing styles
import './GroupProfile.css';
// firebase context
import { useAuth } from '../../context/firebase/authContext';
import { USER_ORGANIZATIONS_BY_UID } from '../../GraphQL/Profile/USER_ORGANIZATIONS/USER_ORGANIZATIONS_BY_UID';
import {
    UserByUID,
    UserByUIDVariables,
} from '../../GraphQL/__generated__/UserByUID';

// ! mock data
const MOCK_GROUP_SKILLS_DATA = [
    {
        id: '1',
        tasksCompleted: 107,
        skillTitle: 'Podcasting Studio',
        skillDescription:
            'Utilize our state-of-the-art podcast studio by spending your Balance Tokens',
    },
    {
        id: '2',
        tasksCompleted: 219,
        skillTitle: 'Event Spaces',
        skillDescription:
            'Rent out our open event spaces by spending your Balance Tokens',
    },
];

const GroupProfile: React.FC = () => {
    const { currentUser } = useAuth();
    const fuser = new FirebaseHelper(currentUser);
    const { id } = useParams<{ id: string }>(); // group id
    const history = useHistory();
    const [toggleMenu, setToggleMenu] = useState<boolean>(false); // handles hamburger menu
    const handleMenuToggle = () => setToggleMenu((prevToggle) => !prevToggle);
    const [somethingToggle, setSomethingToggle] = useState<number>(0);
    const { data, loading } = useQuery<GetOrgById, GetOrgByIdVariables>(
        GET_ORG_BY_ID,
        { variables: { id: parseInt(id) } }
    );
    const { data: currentUserData, loading: currentUserDataLoading } = useQuery<
        UserByUID, // query return return type (shape of data)
        UserByUIDVariables // query request type       (shape of variables)
    >(USER_ORGANIZATIONS_BY_UID, { variables: { subId: currentUser.uid } });

    if (loading || currentUserDataLoading) {
        return <Loading />;
    }

    //redirects user if not in group
    if (currentUserData != undefined) {
        if (
            !currentUserData.profile.organizations
                .map((orgs) => orgs.id)
                .includes(Number(id))
        ) {
            return <Redirect to='/' />;
        }
    }

    const filteredJobsPosted = data.organization.jobsPosted.filter(
        (jobPost) => {
            const jobPostStartDate = moment(jobPost.startDate).tz(
                getLocalStorage('tz'),
                false
            );
            const jobPostStartTime = moment(jobPost.startTime).tz(
                getLocalStorage('tz'),
                false
            );

            const jobCreated = moment(jobPost.createdAt);

            return (
                (moment() < jobPostStartTime &&
                    moment() < jobPostStartDate &&
                    jobPost.status !== Status.REMOVED &&
                    jobCreated > moment('03/21/2021')) || // renders if the task hasnt expired yet
                (jobPost.startDate === null &&
                    jobPost.startTime === null &&
                    jobPost.status !== Status.REMOVED &&
                    jobCreated > moment('03/21/2021')) || // renders if start date/time are tbd
                (jobPost.startDate !== null &&
                    jobPost.startTime === null &&
                    jobPost.status !== Status.REMOVED &&
                    jobCreated > moment('03/21/2021')) || // renders if the start date is known but the start time is tbd
                (jobPost.startDate === null &&
                    jobPost.startTime !== null &&
                    jobPost.status !== Status.REMOVED &&
                    jobCreated > moment('03/21/2021')) // renders if start date is tbd but the start time is known
            );
        }
    );

    // Reverses the job list in groups as they aren't in the right position normally.
    filteredJobsPosted.reverse();

    if (!getLocalStorage(id)) {
        localStorage.setItem(id, JSON.stringify({ admin: 0 }));
    }

    const toggleAdminMode = () => {
        if (getLocalStorage(id).admin == 0) {
            localStorage.setItem(id, JSON.stringify({ admin: 1 }));
            setSomethingToggle(1);
        } else {
            setLocalStorage(id, { admin: 0 });
            setSomethingToggle(0);
        }
    };

    return (
        <IonPage>
            <IonContent>
                <IonGrid className='profile__wrap'>
                    {toggleMenu ? (
                        <IonCol className='menu__sidebar--active'>
                            <UserMenu
                                handleMenuToggle={handleMenuToggle}
                                userUid={fuser.uid}
                            />
                        </IonCol>
                    ) : (
                        <IonCol className='menu__sidebar--inactive' />
                    )}
                    {toggleMenu ? (
                        <UserMenuBackdrop handleMenuToggle={handleMenuToggle} />
                    ) : null}
                    <IonRow className='profileStates__tabs--container'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            sizeMd='12'
                            className='profileStates__tabs--wrap'
                        >
                            <IonButton
                                fill='clear'
                                onClick={handleMenuToggle}
                                color='ion-primary'
                                className='profileStates__tabs--btn'
                            >
                                <IonIcon
                                    className='profileStates__tabs--btn--icon'
                                    icon={menuOutline}
                                />
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    {/* profile header start */}
                    <GroupProfileHeader
                        communityName={data.organization.name}
                        profileImg={
                            data.organization.orgPicture === null ||
                            data.organization.orgPicture.length === 0
                                ? 'https://is3-ssl.mzstatic.com/image/thumb/Purple123/v4/3e/62/e2/3e62e239-f433-bc84-3775-21af60499ec7/source/512x512bb.jpg'
                                : data.organization.orgPicture
                        }
                    />
                    {/* profile bio start */}
                    <IonRow className='profileBio'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='profileBio__col--btn'
                        >
                            <IonButton
                                fill='clear'
                                // expand='block'
                                // className='profileBio__btn'
                                // color='ion-primary'
                            >
                                {/* <IonIcon
                                    icon={chatbubbleEllipses}
                                    slot='start'
                                />{' '}
                                Message Us */}
                            </IonButton>
                        </IonCol>

                        <IonRow className='profileBio__row--bio'>
                            <IonCol
                                sizeXs='11'
                                sizeSm='11'
                                className='profileBio__col--bio'
                            >
                                <p className='profileBio__bio'>
                                    {/*{data.organization.description}*/}
                                </p>
                                {/* comment admin toggle for now */}
                                {/* <IonButton
				onClick={()=>toggleAdminMode()}
				>
				Admin Mode
				</IonButton> */}
                                {/* {getLocalStorage(id).admin == 1 ? 'admin on' : 'admin off'} 	 */}
                            </IonCol>
                        </IonRow>
                    </IonRow>
                    {/* group members button */}
                    <IonRow className='groupMembers'>
                        <IonButton
                            fill='clear'
                            onClick={() =>
                                history.push(
                                    `/search/group/${
                                        !loading && data.organization.code
                                    }`
                                )
                            }
                            color='ion-primary'
                            className='group-members--btn'
                        >
                            Group Members & Skills{' '}
                            <IonIcon src={groupMembers} slot='start' />
                        </IonButton>
                    </IonRow>
                    {/* group skills offered */}
                    <IonRow className='skills'>
                        <IonRow className='skills__row'>
                            <IonCol
                                className='groupSkills__col--title'
                                sizeXs='12'
                                sizeSm='12'
                            >
                                <h2 className='skills__title'>
                                    What We Can Offer
                                </h2>
                            </IonCol>
                        </IonRow>
                        <IonGrid className='groupSkills__container'>
                            <IonRow className='groupSkills__row'>
                                {/* show fallback instead of mock data */}
                                {/*
                                <IonCol
                                  className='tasksFallback'
                                  style={{ backgroundColor: '#fff' }}
                                >
                                    <h3 className='tasksFallback__title'>
                                        {data.organization.description}
                                    </h3>
                                </IonCol>
                                */}

                                {MOCK_GROUP_SKILLS_DATA.length < 0 ? (
                                    MOCK_GROUP_SKILLS_DATA.map((skill) => (
                                        <SkillsOfferedByGroup
                                            key={skill.id}
                                            firstName='Samuel'
                                            {...skill}
                                        />
                                    ))
                                ) : (
                                    <IonCol
                                        className='groupTasksFallback'
                                        style={{ backgroundColor: '#fff' }}
                                    >
                                        <h3 className='groupTasksFallback__title'>
                                            {data.organization.description}
                                        </h3>
                                    </IonCol>
                                )}
                            </IonRow>
                        </IonGrid>
                    </IonRow>
                    {/* group opportunities */}
                    <IonRow className='ion-padding opps'>
                        <IonRow className='opps__header--wrap'>
                            <IonCol
                                className='opps__header--col'
                                sizeSm='12'
                                sizeXs='12'
                            >
                                <h1 className='opps__header--title'>
                                    New Opportunities
                                </h1>
                                <IonButton
                                    fill='clear'
                                    onClick={() =>
                                        history.push(
                                            `/create-Task/${id}/${data.organization.code}`
                                        )
                                    }
                                    color='ion-primary'
                                    className='opps__header--btn'
                                    id='create-task-group-btn'
                                >
                                    + Create a Task
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonRow>
                    <IonRow className='groupTaskItem__row'>
                        {filteredJobsPosted.length > 0 ? (
                            filteredJobsPosted.map((task) => (
                                <GroupTaskItem
                                    key={task.id}
                                    id={task.id}
                                    title={task.title}
                                    description={task.description}
                                    recipientUid={task.recipient.uid}
                                    firstName={task.recipient.firstName}
                                    lastName={task.recipient.lastName}
                                    groupName={data.organization.name}
                                    profilePicture={
                                        task.recipient.profilePicture
                                    }
                                    adminId={task.adminId}
                                    adminPicture={
                                        data.organization.orgPicture === null ||
                                        data.organization.orgPicture.length ===
                                            0
                                            ? 'https://is3-ssl.mzstatic.com/image/thumb/Purple123/v4/3e/62/e2/3e62e239-f433-bc84-3775-21af60499ec7/source/512x512bb.jpg'
                                            : data.organization.orgPicture
                                    }
                                />
                            ))
                        ) : (
                            <IonCol
                                className='tasksFallback'
                                style={{ backgroundColor: '#f3f5f7' }}
                            >
                                <h3 className='tasksFallback__title'>
                                    There is currently no
                                    <br /> new opportunities.
                                </h3>
                            </IonCol>
                        )}
                    </IonRow>
                </IonGrid>
                <NavBar />
            </IonContent>
        </IonPage>
    );
};

export default GroupProfile;
