// modules & hooks
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useAuth } from 'context/firebase/authContext';
import { useOnboardingFlow } from 'utils/onboarding';
// components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
} from '@ionic/react';
import { InputErrorMsgCentered } from 'components/InputErrorMsg/InputErrorMsg';
import BackButton from 'components/BackButton';
import Strokes from 'components/Strokes';
// assets
import yingLogo from 'assets/ying-logo-large-blk.png';
// styles
import './JoinGroup.css';
// queries
import { gql_ADD_GROUP_TO_PROFILE } from 'GraphQL/Profile/ADD_GROUP_TO_PROFILE/ADD_GROUP_TO_PROFILE';
import {
    ADD_GROUP_TO_PROFILE,
    ADD_GROUP_TO_PROFILEVariables,
} from 'GraphQL/__generated__/ADD_GROUP_TO_PROFILE';
import { USER_MENU_STATE } from 'GraphQL/Profile/USER_MENU_STATE/USER_MENU_STATE';
// helpers
import { setLocalStorage } from 'helpers/localStorage/localStorageHelper';

type GroupCode = {
    code: string;
};

interface GroupMeta {
    id: number;
    code: string;
    name: string;
    orgPicture: string;
}

const JoinGroup = () => {
    const { currentUser } = useAuth();
    const [groupMeta, setGroupMeta] = useState<GroupMeta>({
        id: null,
        code: null,
        name: null,
        orgPicture: null,
    });
    const { register, handleSubmit, errors } = useForm<GroupCode>();
    const history = useHistory();
    const [code, setCode] = useState(
        localStorage.getItem('memberCode')
            ? localStorage.getItem('memberCode')
            : ''
    );
    const [validationFeedback, setValidationFeedback] = useState('');
    const { isOnboarding } = useOnboardingFlow();

    localStorage.removeItem('refreshSignIn');
    localStorage.removeItem('memberCode');

    const [
        exe__ADD_GROUP_TO_PROFILE,
        {
            data: data__ADD_GROUP_TO_PROFILE,
            loading: loading__ADD_GROUP_TO_PROFILE,
            error: error__ADD_GROUP_TO_PROFILE,
        },
    ] = useMutation<ADD_GROUP_TO_PROFILE, ADD_GROUP_TO_PROFILEVariables>(
        gql_ADD_GROUP_TO_PROFILE
    );

    const handleOnSubmit = (data: GroupCode) => {
        // check if there is some data.

        exe__ADD_GROUP_TO_PROFILE({
            variables: {
                uid: currentUser.uid,
                code: data.code,
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: USER_MENU_STATE,
                    variables: {
                        subId: currentUser.uid,
                    },
                },
            ],
        })
            .then((r) => {
                localStorage.removeItem('memberCode');

                if (r.data.addGroupToProfile) {
                    setLocalStorage('groupMeta', {
                        // set in local storage
                        ...groupMeta,
                        name: r.data.addGroupToProfile.name,
                        id: r.data.addGroupToProfile.id,
                        code: code,
                        orgPicture: r.data.addGroupToProfile.orgPicture,
                    });
                    history.push(
                        `/joinGroup/${r.data.addGroupToProfile.name}/${r.data.addGroupToProfile.id}/confirmation`
                    );
                } else {
                    // returns null if no group matched
                    setValidationFeedback('no group for code: ' + code);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };

    return (
        <IonPage>
            <IonContent scrollY={false}>
                {!isOnboarding() && <BackButton />}
                <IonGrid className='joinGroup__grid'>
                    <IonRow className='joinGroup'>
                        <IonCol
                            sizeXs='12'
                            sizeSm='12'
                            className='joinGroup__container'
                        >
                            <Strokes top={true} green={false} />
                            <IonCol
                                sizeXs='10'
                                sizeSm='10'
                                className='joinGroup__container'
                            >
                                <IonImg
                                    className='joinGroup__img'
                                    alt='ying-logo'
                                    src={yingLogo}
                                />
                                <h1 className='joinGroup__title'>
                                    <span className='joinGroup__title--span'>
                                        Join{' '}
                                    </span>
                                    New Group
                                </h1>
                                <p className='joinGroup__text'>
                                    Enter the unique group code associated with
                                    the group you wish to join. Happy Skill
                                    Sharing!
                                </p>
                                <IonRow className='joinGroup__error--container'>
                                    <IonCol
                                        sizeXs='10'
                                        sizeSm='10'
                                        className='joinGroup__error--col'
                                    >
                                        {loading__ADD_GROUP_TO_PROFILE && (
                                            <p>Checking code...</p>
                                        )}
                                        {errors.code &&
                                            errors.code.type === 'required' && (
                                                <InputErrorMsgCentered errorMsg='Group code is required' />
                                            )}
                                        {validationFeedback && (
                                            <InputErrorMsgCentered
                                                errorMsg={validationFeedback}
                                            />
                                        )}
                                    </IonCol>
                                </IonRow>
                                <form
                                    onSubmit={handleSubmit(handleOnSubmit)}
                                    className='joinGroup__form'
                                >
                                    <input
                                        ref={register({
                                            required: 'Group code is required',
                                        })}
                                        type='text'
                                        className={`${
                                            errors.code
                                                ? 'joinGroup__form--input joinGroup__form--input--error'
                                                : 'joinGroup__form--input'
                                        }`}
                                        name='code'
                                        placeholder='Group Code'
                                        value={code.trim()}
                                        onChange={(e) =>
                                            setCode(e.target.value)
                                        }
                                        autoComplete='off'
                                        formNoValidate
                                    />
                                    <IonButton
                                        fill='clear'
                                        color='ion-primary'
                                        className='joinGroup__form--submit'
                                        type='submit'
                                    >
                                        Join Group
                                    </IonButton>
                                </form>
                            </IonCol>
                        </IonCol>
                    </IonRow>
                    <Strokes bot={true} />
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default JoinGroup;
